<template>
  <h1 class="page-title py-4 mb-0" :class="{ 'mb-5' : !removeMargin, 'text-center' : centered }">
    <slot>{{ title }}</slot>
    <template v-if="subtitle"> <small :class="{ 'text-center' : centered }">({{ subtitle }})</small></template>
  </h1>
</template>

<script>

export default {
  name: 'page-title',
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    removeMargin: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

.page-title {
  margin-bottom: 15px;

  small {
    text-transform: initial;
    margin-left: 15px;
  }
}

</style>
